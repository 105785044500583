<template>
<div class="M-Auto ReportFontSize">
  <CookiesTabs :Router="false" @selected="ChangeOrders" :Default="{ Name: LanguageSelector('Active Orders', 'الطلبات الحالية'), Value: 'order' }" :Tabs="[{ Name: LanguageSelector('Active Orders', 'الطلبات الحالية'), Value: 'order' }, { Name: LanguageSelector('Archived Orders', 'الطلبات المأرشفة'), Value: 'DoneOrders' }]"></CookiesTabs>
  <div class="D-Flex ReportFilter">
    <input @blur="SearchNow" @keypress="SearchKey" v-model="SearchInput" class="CookiesInput F1_5em" :placeholder="LanguageSelector('Search', 'بحث')" type="text">
    <!-- Search Button -->
    <div v-if="SearchInput !== CurrentSearch" @click="SearchNow" class="SearchBarIcon HoverGlow"><CookiesIcon class="M-Auto" style="width: 2em; height: 2em;" :ID="'Search'"></CookiesIcon></div>
    <!-- Cancel Search Button -->
    <div v-if="CurrentSearch !== '' && SearchInput === CurrentSearch" @click="SearchInput = ''; CurrentSearch = ''" class="SearchBarIcon HoverGlow"><CookiesIcon class="M-Auto" style="width: 2em; height: 2em;" :ID="'Removed'"></CookiesIcon></div>
    <div class="D-Flex" style="flex:3">
      <CookiesDropDown :Res="'R_Orders_Customer'" :Type="'Object'" :Selector="'Company'" :Name="LanguageSelector('Customer', 'العميل')" :Options="CookiesDataCustomers"></CookiesDropDown>
      <CookiesDropDown v-if="Type === 'order'" :Res="'R_Orders_Status'" :CookiesIcons="StatusMapper('', 'list')" :Type="'Object'" :Selector="LanguageSelector('ENG', 'ARA')" :Options="StatusMapper('', 'objlist')" :Name="LanguageSelector('Status', 'الحالة')" ></CookiesDropDown>
      <CookiesDropDown v-if="Type === 'DoneOrders'" :Res="'R_DoneOrders_Status'" :CookiesIcons="['Done', 'Closed']" :Type="'Object'" :Selector="LanguageSelector('ENG', 'ARA')" :Options="StatusMapper([{ ARA: 'تم التسليم', ENG: 'Done', value: 'Done' }, { ARA: 'مغلقة', ENG: 'Closed', value: 'Closed' }])" :Name="LanguageSelector('Status', 'الحالة')" ></CookiesDropDown>
      <CookiesDropDown :Res="'R_Orders_Lines'" :Type="'Object'" :Selector="'Name'" :Name="LanguageSelector('Line', 'الخط')" :Options="CookiesDataLines"></CookiesDropDown>
      <CookiesDropDown v-if="Type === 'order'" :Res="'R_Orders_Drivers'" :Type="'Object'" :Selector="['Employee','Name']" :Name="LanguageSelector('Driver', 'السائق')" :Options="CookiesDataDrivers"></CookiesDropDown>
      <div @click="printIT" class="PrintButton D-Flex RowMargin">
        <CookiesIcon style="width: 1.2em; height:1.2em; margin: 0 0.5em;" :ID="'Printer'"></CookiesIcon>
        <span class="F-Bold M-Auto">Print</span>
      </div>
    </div>
  </div>
  <div class="CookiesRowSplitter"></div>
  <CookiesTable :Loading="Loading" id="TableToPrint" :DefaultSort="{ value: -1, name: ['UPDDate'] }" :CookiesID="'OrdersReportTable'" :TableName="TableName" :Data="FilteredOrders" :Fields="Fields" :Search="CurrentSearch"></CookiesTable>
</div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      Fields: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Bar Code', 'بار كود'),
          value: ['BarCode'],
          sort: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Customer', 'العميل'),
          value: ['Customer', 'Company'],
          sort: true
        },
        {
          name: this.LanguageSelector('Submission Date', 'تاريخ الادخال'),
          value: ['SubmissionDate'],
          sort: true,
          type: 'datetime'
        },
        {
          name: this.LanguageSelector('Expected Date', 'تاريخ التوصيل'),
          value: ['ExpectedDeliveryDate'],
          sort: true,
          type: 'date'
        },
        {
          name: this.LanguageSelector('Status', 'الحالة'),
          value: ['StatusDecoded'],
          sort: true
        },
        {
          name: this.LanguageSelector('Location', 'الموقع'),
          value: ['SubArea'],
          sort: true
        },
        {
          name: this.LanguageSelector('Address', 'العنوان'),
          value: ['Address'],
          sort: true
        },
        {
          name: this.LanguageSelector('Client Name', 'اسم الزبون'),
          value: ['Client', 'Name'],
          sort: true
        },
        {
          name: this.LanguageSelector('Client Number', 'رقم الزبون'),
          value: ['Client', 'PhoneNum'],
          sort: true
        },
        {
          name: this.LanguageSelector('Fees', 'القيمة'),
          value: ['Fees'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true
        },
        {
          name: this.LanguageSelector('Total Ammount', 'اجمالي السعر'),
          value: ['AmountTotal'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true
        },
        {
          name: this.LanguageSelector('Assigned Driver', 'السائق المحدد'),
          value: ['OrderDriver', 'DriverName'],
          sort: true
        },
        {
          name: this.LanguageSelector('Notes', 'الملاحظات'),
          value: ['Notes'],
          sort: true
        }
      ],
      SearchInput: '',
      CurrentSearch: '',
      Orders: [],
      CookiesDataCustomers: [],
      CookiesDataLines: [],
      CookiesDataDrivers: [],
      Loading: true,
      Tabs: [
        { Name: this.LanguageSelector('Main Areas', 'المناطق الرئيسية'), Link: '/Areas/MainAreas', Value: 'MainAreas' },
        { Name: this.LanguageSelector('Sub Areas', 'المناطق الفرعية'), Link: '/Areas/SubAreas', Value: 'SubAreas' },
        { Name: this.LanguageSelector('Lines', 'الخطوط'), Link: '/Areas/Lines', Value: 'Lines' }
      ]
    }
  },
  computed: {
    Type () {
      return this.$route.params.Type
    },
    FilteredOrders () {
      var Filtered = []
      this.Orders.forEach(element => {
        var FilterIt = true
        try {
          if (this.$store.state.CookiesMemory.R_Orders_Customer.Id !== this.DeepFetcher(element, ['Customer', 'CustomerID']) & !(['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Orders_Customer.Company) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        try {
          if (this.$store.state.CookiesMemory.R_Orders_Lines.Name !== this.DeepFetcher(element, ['Line', 'Name']) & !(['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Orders_Lines.Name) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        try {
          if (this.Type === 'order' && this.$store.state.CookiesMemory.R_Orders_Drivers.Employee.Name !== this.DeepFetcher(element, ['OrderDriver', 'DriverName']) & !(['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Orders_Drivers.Employee.Name) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        try {
          if (this.Type === 'order' && this.$store.state.CookiesMemory.R_Orders_Status[this.LanguageSelector('ENG', 'ARA')] !== this.DeepFetcher(element, ['StatusDecoded']) & !(['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_Orders_Status[this.LanguageSelector('ENG', 'ARA')]) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        try {
          if (this.Type === 'DoneOrders' && this.$store.state.CookiesMemory.R_DoneOrders_Status[this.LanguageSelector('ENG', 'ARA')] !== this.DeepFetcher(element, ['StatusDecoded']) & !(['All', 'الكل'].indexOf(this.$store.state.CookiesMemory.R_DoneOrders_Status[this.LanguageSelector('ENG', 'ARA')]) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        if (FilterIt) {
          element.StatusDecoded = this.StatusMapper(element.Status)
          Filtered.push(element)
        }
      })
      return Filtered
    },
    CurrentDate () {
      var DateRange = {}
      DateRange.To = this.$store.getters.CurrentDate.To
      DateRange.From = this.$store.getters.CurrentDate.From
      return DateRange
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    TableName () {
      var ENG = 'Orders report'
      var ARA = 'كشف طلبات'
      var Line = ''
      if (this.$store.state.CookiesMemory.R_Orders_Lines && !(['All', 'الكل'].indexOf(this.DeepCloner(this.$store.state.CookiesMemory.R_Orders_Lines.Name)) >= 0)) {
        Line = ' (' + this.$store.state.CookiesMemory.R_Orders_Lines.Name + ')'
      }
      if (this.$store.state.CookiesMemory.R_Orders_Customer && !(['All', 'الكل'].indexOf(this.DeepCloner(this.$store.state.CookiesMemory.R_Orders_Customer.Company)) >= 0)) {
        ENG = 'Orders report' + 'for (' + this.$store.state.CookiesMemory.R_Orders_Customer.Company + ') from: ' + this.CurrentDate.From + ' to: ' + this.CurrentDate.To + Line
        ARA = 'كشف طلبات' + ' للعميل (' + this.$store.state.CookiesMemory.R_Orders_Customer.Company + ') من: ' + this.CurrentDate.From + ' الى: ' + this.CurrentDate.To + Line
      } else {
        ENG = 'Orders report from: ' + this.CurrentDate.From + ' to: ' + this.CurrentDate.To + Line
        ARA = 'كشف طلبات من ' + this.CurrentDate.From + ' الى: ' + this.CurrentDate.To + Line
      }
      return this.LanguageSelector(ENG, ARA)
    }
  },
  mounted () {
    if (!this.$store.state.UserManagment.Status) {
      this.$router.replace('/SignIn')
    }
    this.ReadCustomers()
    this.ReadLines()
    this.ReadDrivers()
    this.ReadOrders()
  },
  methods: {
    ReadLines () {
      this.Get('Area/getLine').then(response => {
        if (response === null) {
          return
        }
        this.CookiesDataLines = response
      }, error => {
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadOrders () {
      if (!(['order', 'DoneOrders'].indexOf(this.Type) >= 0)) {
        return
      }
      this.Loading = true
      this.Get(this.Type + '?' + this.DateAPIURL()).then(response => {
        if (response !== null & response !== undefined) {
          this.Orders = response
        }
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        if (response === null) {
          return
        }
        this.CookiesDataDrivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Get('Customers').then(response => {
        if (response === null) {
          return
        }
        this.CookiesDataCustomers = response
      }, error => {
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      var ToPrint = document.getElementById('TableToPrint')
      this.$store.state.Printing = ToPrint.cloneNode([true])
    },
    ChangeOrders (ordersType) {
      this.$router.replace({ params: { Type: ordersType.Value } })
    },
    SearchNow () {
      this.CurrentSearch = this.SearchInput
    },
    SearchKey () {
      if ((event.which) === 13) {
        this.CurrentSearch = this.SearchInput
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    },
    Type: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    }
  }
}
</script>
<style scoped>
.ReportFontSize{
  margin-top: -3.75em;
}
.RowMargin{
  margin: auto 0.5em;
}
.CookiesInput{
  padding: 5px 10px;
  border: solid 1px var(--wshade0);
  filter: drop-shadow(var(--shadow0));
  /* background: var(--colorw2); */
}
.PrintButton{
  border-radius: 5em;
  background: v-bind('$store.getters.Theme.BG2');
  padding: 0.7em 1.2em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
.CookiesSelectBotton{
  margin: auto 0.5em !important;
}
@media (max-width: 800px) {
  .ReportFilter{
    flex-direction: column;
  }
}
.TabButton{
  padding: 0.5em 3em;
  margin: 0.15em;
  text-decoration: none;
  filter: drop-shadow(var(--shadow2));
  color: v-bind('$store.getters.Theme.color1');
  border-radius: var(--rad3);
  opacity: 0.6;
  transition: opacity 0.3s ease;
  cursor: pointer;
  position: relative;
}
.TabButton:hover::after{
  opacity: 0.5;
}
.TabButton::after{
  content: '';
  position: absolute;
  inset: 0;
  border-radius: var(--rad3);
  /* z-index: -1; */
  opacity: 0;
  background: v-bind('$store.getters.Theme.BG5');
  transition: opacity 0.3s ease;
}
.SelectedTab{
  opacity: 1;
}
.SelectedTab::after{
  z-index: -1;
  opacity: 1;
}
.TabContainer{
  width: max-content;
  justify-content: center;
  display: flex;
  background: var(--shade1);
  margin-top: 1.9em;
  margin-bottom: -0.1em;
  padding: 0.3em;
  border-radius: var(--rad3);
}
</style>
